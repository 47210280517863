// Push Notification Preview Component For Vue
//-- HEADER --//
$vnpn-pixel_4-header-height: 10%;
//-- NOTIFICATION APPLICATION ICON --//
$vnpn-pixel_4-notification-application-icon-background-color: #F6993F;
//-- NOTIFICATION CONTENT --//
$vnpn-pixel_4-notification-content-font-size: 10px !default;
@import '~vue-push-notification-preview/src/assets/devices.scss';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Noty
@import '~vuejs-noty/dist/vuejs-noty.css';

// Vue icon
.v-icon,
.custom-icon {
    width: 24px;
}
.button-icon {
    width: 16px;
}
.md-icon {
    width: 20px !important;
    height: 20px !important;
}

.vpnp-notification-content-text {
    line-height: 1.45em
}